import React from 'react'
import PropTypes from 'prop-types'
import Image from './Image'
import cx from 'classnames'

const LogoGrid = ({ logoGrid, className }) => {
    const heading = logoGrid.heading ?? null
    const logoGridImages = logoGrid.logoGridImages.slice(0, 10) ?? null
    const smallGrid = logoGridImages.length <= 4
    const largeGrid = logoGridImages.length > 4

    return (
        <div className={cx(className, 'max-w-1264')}>
            {heading && <h3 className={cx('hd-sm text-center')}>{heading}</h3>}
            {logoGridImages && (
                <ul
                    className={cx(
                        {
                            'flex md-d:flex-col md:flex-wrap items-center':
                                smallGrid && !largeGrid,
                            'grid md:grid-cols-5': largeGrid,
                        },
                        'justify-center md-d:space-y-16 md:gap-60 mt-40'
                    )}
                >
                    {logoGridImages.map(({ title, logo }, i) => (
                        <li
                            key={i}
                            className={cx('flex justify-center', {
                                'w-250': smallGrid,
                                'w-150 md:w-120 xl:w-180': largeGrid,
                            })}
                        >
                            <Image
                                image={logo}
                                alt={title}
                                className={cx('w-full')}
                            />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    )
}

LogoGrid.propTypes = {
    logoGrid: PropTypes.shape({
        heading: PropTypes.string,
        logoGridImages: PropTypes.array,
    }),
    className: PropTypes.string,
}

export default LogoGrid
