import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Markdown from 'react-markdown'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import PageBackgroundImage from '../components/PageBackgroundImage'
import LogoGrid from '../components/LogoGrid'

export const ContactPageTemplate = ({
    title,
    backgroundImage,
    content,
    logoGrid,
}) => {
    return (
        <section
            className={cx('max-w-1264 mx-auto px-24 lg:px-32 py-60 lg:pb-120')}
        >
            {backgroundImage && <PageBackgroundImage image={backgroundImage} />}

            <h1 className={cx('hd-jb text-purple-700 mb-32')}>{title}</h1>

            <div className={cx('md:flex md:justify-between')}>
                <div className={cx('md-d:mb-24 md:w-2/5')}>
                    {content.map((item) => (
                        <div className={cx('mb-64')} key={item.heading}>
                            <h2 className={cx('hd-md mb-16')}>
                                {item.heading}
                            </h2>

                            {item.subHeading && (
                                <p
                                    className={cx(
                                        'text-21 leading-relaxed mb-16'
                                    )}
                                >
                                    {item.subHeading}
                                </p>
                            )}

                            <Markdown
                                source={item.text}
                                className="rich-text"
                            />
                        </div>
                    ))}
                </div>
                <div className={cx('bg-gray-100 p-24 md:w-1/2 lg:p-60')}>
                    <ContactForm title={title} />
                </div>
            </div>
            {logoGrid &&
             <LogoGrid logoGrid={logoGrid} className="mt-60" />}
        </section>
    )
}

ContactPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    backgroundImage: PropTypes.object,
    content: PropTypes.array,
    logoGrid: PropTypes.shape({
        heading: PropTypes.string,
        logoGridImages: PropTypes.array,
    }),
}

const ContactPage = ({ data }) => {
    const { markdownRemark: post } = data

    return (
        <Layout
            title={post.frontmatter.title}
            description={post.frontmatter.subHeading}
        >
            <ContactPageTemplate
                title={post.frontmatter.title}
                backgroundImage={post.frontmatter.backgroundImage}
                content={post.frontmatter.content}
                logoGrid={post.frontmatter.logoGrid}
            />
        </Layout>
    )
}

ContactPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ContactPage

export const contactPageQuery = graphql`
    query ContactPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            frontmatter {
                title
                backgroundImage {
                    extension
                    publicURL
                }
                content {
                    heading
                    subHeading
                    text
                }
                logoGrid {
                    heading
                    logoGridImages {
                        title
                        logo {
                            childImageSharp {
                                fluid(maxWidth: 300, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
