import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const Field = ({ children }) => {
    return <div className={cx('mb-24')}>{children}</div>
}

Field.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Field
