import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const Label = ({ label, id, required = true }) => {
    return (
        <label
            htmlFor={id}
            className={cx('block mb-12 font-medium leading-relaxed')}
        >
            {label}
            {required && (
                <>
                    <span aria-hidden="true">*</span>
                    <span className={cx('sr-only')}>Required</span>
                </>
            )}
        </label>
    )
}

Label.propTypes = {
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
}

export default Label
