import { navigate } from 'gatsby-link'
import encode from './encode'

const handleSubmit = (values) => (e) => {
    e.preventDefault()
    const form = e.target
    const formName = form.getAttribute('name')

    fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
            'form-name': formName,
            ...values,
        }),
    })
        .then(() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'interaction',
                event_category: 'Form Submissions',
                event_action: form.dataset.gtmAction || formName,
                event_label: window.location.href,
            })

            navigate(form.getAttribute('action'))
        })
        .catch((error) => alert(error))
}

export default handleSubmit
