import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Label from './Label'

const Textarea = ({ label, name, handleChange, required = true }) => {
    return (
        <>
            <Label label={label} id={name} required={required} />
            <textarea
                className={cx(
                    'w-full text-gray-500 p-20 rounded-8 duration-200 focus:outline-none focus:shadow-textarea'
                )}
                name={name}
                id={name}
                rows="4"
                required={required}
                onChange={handleChange}
            />
        </>
    )
}

Textarea.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
}

export default Textarea
