import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import Label from './Label'

const Input = ({ label, name, type, handleChange, required = true }) => {
    return (
        <>
            <Label label={label} id={name} required={required} />
            <input
                className={cx(
                    'w-full bg-transparent border-gray-300 border-b text-gray-500 p-4 duration-200 rounded-none focus:outline-none focus:shadow-input'
                )}
                type={type}
                name={name}
                id={name}
                required={required}
                onChange={handleChange}
            />
        </>
    )
}

Input.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
}

export default Input
