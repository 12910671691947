import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import Image from './Image'

const PageBackgroundImage = ({ image }) => {
    return (
        <Image
            image={image}
            className={cx(
                'absolute top-0 max-w-none left-1/2 transform -translate-x-1/2 -z-1 opacity-50'
            )}
        />
    )
}

PageBackgroundImage.propTypes = {
    image: PropTypes.object.isRequired,
}

export default PageBackgroundImage
