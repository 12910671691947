import React from 'react'
import cx from 'classnames'
import ArrowRight from '../../img/inline/arrow-right.svg'

const SubmitButton = () => {
    return (
        <button
            className={cx(
                'bg-blue-500 text-white rounded-6 text-16 font-bold min-w-180 px-20 py-16 duration-200 hover:bg-blue-700'
            )}
            type="submit"
        >
            <span className={cx('flex justify-between items-center')}>
                <span>Submit</span>
                <span>
                    <ArrowRight />
                </span>
            </span>
        </button>
    )
}

export default SubmitButton
